import React from 'react';

const SpinnerLoader = () => {
    return (
      <div style={{ margin: '10% auto', textAlign: 'center' }}>
        <div className="spinner-border spinner-border-xl" role="status">            
        </div><br />
        <span>Cargando...</span>
    </div>
    );
}
export default SpinnerLoader;