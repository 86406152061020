import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { FiCheck, FiX } from 'react-icons/fi';

const SaleItemNotes = props => {

    const inputRef = useRef(null);

    const confirm = () => {
        let item = props.item;
        item.notes = inputRef.current.value;
        props.updateNote(item);
        props.onHide();
    }
    return (
        <Modal show={props.show} size="md" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    Notas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <textarea ref={inputRef} defaultValue={props.item.notes} className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => props.onHide()} type="button" className="btn btn-secondary"><FiX/> Cancelar</button>
                <button onClick={confirm} type="button" className="btn btn-primary"><FiCheck /> Ok</button>
            </Modal.Footer>
        </Modal>
    );
}

export default SaleItemNotes;