import React, { useState, useContext } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import { PosContext } from '../../Contexts/PosContext';
import CurrencyFormat from '../../Utils/CurrencyFormat';

import SaleServices from '../Sales/SalesServices';

import * as Notify from '../../Utils/Notify';

const Pay = props => {

    const { saleItems, setSaleItems, typeDoc, paymentMethods, sendPrint } = useContext(PosContext);

    const [payments, setPayments] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const sessionId = parseInt(localStorage.getItem('pos_sessionId'));    
    const subsidiaryId = parseInt(localStorage.getItem('pos_subsidiary'));   

    const addPayment = (id) => {      
        Notify.clearAll();  
        const totalToPay = saleItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);        
        const balance = totalToPay - payments.reduce((acc, pay) => acc + pay.amount, 0);
        const method = paymentMethods.find(t => t.id === id);

        if (balance > 0) {
            let payAmount = 0;           
            if (method.id !== 1) {
                payAmount = parseFloat(balance);             
            } else {
                payAmount = parseFloat(paymentAmount);
                if (payAmount === 0) {
                    Notify.send('Debes ingresar monto a pagar en efectivo', 'warning');
                    return;
                }
            }

            let updatedPayments = [];
            const paymentIndex = payments.findIndex(t => t.paymentMethodId === id);
            if (paymentIndex > -1) {
                payments[paymentIndex].amount += payAmount;
                updatedPayments = [...payments];
            } else {
                const newPay = { paymentMethodId: id, typeId:1, name: method.name, amount: payAmount, hasChange: method.hasChange };
                updatedPayments = [...payments, newPay];
            }
            const totalpaid = updatedPayments.reduce((acc, pay) => acc + pay.amount, 0);
            setPayments(updatedPayments);

            let currentPaymentAmount = (totalToPay - totalpaid) < 0 ? 0 : (totalToPay - totalpaid);
            setPaymentAmount(currentPaymentAmount);
        }
    }

    const removePayment = (id) => {
        const totalToPay = saleItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
        const updatedPayments = payments.filter(t => t.paymentMethodId !== id);
        const totalpaid = updatedPayments.reduce((acc, pay) => acc + pay.amount, 0);

        setPayments(updatedPayments);
        setPaymentAmount(totalToPay - totalpaid);
    }

    const addDigit = (number) => {
        let current = paymentAmount.toString();
        const amount = current !== '0' ? current + number.toString() : number.toString();
        setPaymentAmount(amount);
    }

    const clearDigit = () => {
        setPaymentAmount(0);
    }

    const addMoney = (number) => {
        let current = paymentAmount.toString();
        const amount = current !== '0' ? (parseFloat(current) + number).toString() : number.toString();
        setPaymentAmount(amount);
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        setPaymentAmount(value);
    }

    function pad(number) {
        if (number < 10)
            return '0' + number;

        return number;
    }

    function currentDateISOString () {
        const dateNow = new Date();
        return dateNow.getFullYear() +
        '-' + pad(dateNow.getMonth() + 1) +
        '-' + pad(dateNow.getDate()) +
        'T' + pad(dateNow.getHours()) +
        ':' + pad(dateNow.getMinutes()) +
        ':' + pad(dateNow.getSeconds()) +
        '.' + (dateNow.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z';
    }

    const confirmPayment = async (e) => { 
        const singleClick = parseInt(e.detail); 
        if (singleClick !== 1)
            return false;

        setLoading(true);   
        const totalToPay = saleItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
        const totalPaid = payments.reduce((acc, pay) => acc + pay.amount, 0);

        if (totalPaid >= totalToPay && totalPaid > 0 && totalToPay > 0) {
            const dateNow = currentDateISOString();
            const paymentsWithChange = payments.map((pay) => {
                return {
                    paymentMethodId: pay.paymentMethodId,
                    typeId: pay.typeId,
                    sessionPosId: sessionId,
                    methodName: pay.name,
                    amount: pay.hasChange === 1 ? (pay.amount - (totalPaid - totalToPay)) : pay.amount,
                    paymentDate: dateNow
                }
            });            

            const saleData = {                
                sale: {
                    customerId: 1,
                    sessionPosId: sessionId,
                    subsidiaryId: subsidiaryId,
                    emissionDate: dateNow,
                    totalAmount: totalToPay,
                    totalPaid: totalPaid,
                    changeAmount: totalPaid - totalToPay,
                    documentTypeId: typeDoc,
                    currencyId: 1,
                    saleTypeId: 1,
                    saleTypeName: 'Del Giro',
                    paymentTypeId: 1,
                    paymentTypeName: 'Contado',
                    shipmentStatus: 'delivered',
                    status: 'pending'
                },
                detail: saleItems.map(item => {
                    const detailRate = item.isExempt === 0 ? explodeRate(item.sellingRate) : [item.sellingRate, 0, item.sellingRate];
                    return {
                        itemId: item.id,
                        itemName: item.name,
                        unitName: item.unitName,
                        isExempt: item.isExempt,
                        taxPercent: 19,
                        quantity: item.quantity,
                        sellingRate: detailRate[0],
                        subtotal: detailRate[0] * item.quantity,
                        taxAmount: detailRate[1],
                        grossAmount: detailRate[2],
                        shipmentStatus: 'delivered',       
                        notes: item.notes
                    }
                }),
                payments: paymentsWithChange                
            }            
            SaleServices.save(saleData)
                .then(response => {   
                    setLoading(false);                
                    Notify.send(`Venta Finalizada! <strong>Vuelto: ${CurrencyFormat(saleData.sale.changeAmount)}</strong>`, 'success');
                    sendPrint(response.data);
                    finishPayment();
                })
                .catch(e => {            
                    setLoading(false);
                    Notify.clearAll();    
                    Notify.send(e.message, 'error');                    
                })
        } else {
            setLoading(false);
            Notify.clearAll();    
            Notify.send('Debes ingresar monto y forma de pago', 'warning');
        }
    }

    const explodeRate = (amount) => {
        var net = 0.0;
        var tax = 0.0;
        //var gross = 0.0;
        var pivot = 0.0;

        net = parseFloat(amount);
        tax = Math.round(net * 0.19);
        pivot = Math.round(net / 1.19);
        //gross = Math.round(net + Math.round(tax));

        return [pivot, (net - pivot), net];
	}

    const finishPayment = () => {        
        setPayments([]);
        setSaleItems([]);
        setPaymentAmount(0);        
        props.onHide();
        setLoading(false);
    }

    const cancelPayment = () => {
        props.onHide();
        setPayments([]);
        setPaymentAmount(0);
        setLoading(false);
    }

    return (
        <Modal show={props.show} size="xl" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    Transacción de Pago
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6"><h6>Descuentos</h6></div>
                                    <div className="col-md-6 text-right"><h6>0</h6></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6"><h5>Total</h5></div>
                                    <div className="col-md-6 text-right"><h5>{CurrencyFormat(saleItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0))}</h5></div>
                                </div>
                            </div>
                        </div>
                        <table className="table mt-3">
                            <tbody>
                                {payments.map((pay, index) =>
                                    <tr key={index}>
                                        <td><button onClick={() => removePayment(pay.paymentMethodId)} type="button" className="btn btn-sm btn-secondary" disabled={isLoading}><FiX /></button></td>
                                        <td>{pay.name}</td>
                                        <td>{CurrencyFormat(pay.amount)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-12">
                                <input type="text" name="paymentAmount" onChange={handleInputChange} value={paymentAmount} className="form-control form-control-lg text-right" />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-7">
                                <div className="btn-group-vertical btn-calculator" style={{ width: '80%' }}>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(7)} disabled={isLoading}>7</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(8)} disabled={isLoading}>8</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(9)} disabled={isLoading}>9</button>
                                    </div>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(4)} disabled={isLoading}>4</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(5)} disabled={isLoading}>5</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(6)} disabled={isLoading}>6</button>
                                    </div>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(1)} disabled={isLoading}>1</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(2)} disabled={isLoading}>2</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(3)} disabled={isLoading}>3</button>
                                    </div>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => addDigit(0)} disabled={isLoading}>0</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => clearDigit()} disabled={isLoading}>CE</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                {paymentMethods.map((pay, index) => <button key={index} onClick={() => addPayment(pay.id)} className="btn btn-secondary btn-block mb-2" type="button" disabled={isLoading}>{pay.name}</button>)}
                                <br />
                                <button type="button" className="btn btn-outline-secondary btn-block mb-1" onClick={() => addMoney(1000)} disabled={isLoading}>$ 1.000</button>
                                <button type="button" className="btn btn-outline-secondary btn-block mb-1" onClick={() => addMoney(5000)} disabled={isLoading}>$ 5.000</button>
                                <button type="button" className="btn btn-outline-secondary btn-block mb-1" onClick={() => addMoney(10000)} disabled={isLoading}>$ 10.000</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={cancelPayment} type="button" className="btn btn-secondary" disabled={isLoading}><FiX /> Cancelar</button>
                <button onClick={confirmPayment} type="button" className="btn btn-primary" disabled={isLoading}><FiCheck /> Confirmar Pago</button>
            </Modal.Footer>
        </Modal>
    );
}

export default Pay;