import React, { useEffect, useState }  from 'react';
import moment from 'moment';

import { FiCheck } from 'react-icons/fi';
import { MdMonetizationOn } from 'react-icons/md';
import SpinnerLoader from '../Layout/SpinnerLoader';

import SessionService from '../Login/SessionService';

import Authenticator from '../Login/Authenticator';
import * as Notify from '../../Utils/Notify';
import CurrencyFormat from '../../Utils/CurrencyFormat';

const CashManagement = () => {

    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);

    const [status, setStatus] = useState({});
    const [formData, setFormData] = useState({});

    const [authShow, setAuthShow] = useState(false);
    
    const movementTypes = [
        { id: 1, name: 'Retiro' },
        { id: 2, name: 'Saldo Apertura' },
        { id: 3, name: 'Otros Ingresos' }
    ];

    useEffect(() => {
        updateStatus();
    }, []);

    const updateStatus = () => {
        const fetchStatus = async () => {
            const id = parseInt(localStorage.getItem('pos_sessionId'));
            const tenant = parseInt(localStorage.getItem('pos_tenantId'));
            const response = await SessionService.getStatus(id, tenant).catch(e => console.log(e));
            setStatus(response.data);
            setLoading(false);
        }
        fetchStatus();
    }

    const handleInputChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});        
    }

    const typesMovements = () => {
        return [
            { id: 1, name: 'Retiro de Efectivo' },
            { id: 2, name: 'Saldo de Apertura' },
            { id: 3, name: 'Otros Ingresos' }
        ]
    }

    const validate = () => {
        let valid = true;
        if (typeof formData.cashMovementTypeId === 'undefined')
            valid = false;
        if (typeof formData.amount === 'undefined')
            valid = false;
        return valid;        
    }

    const preSubmit = async (event) => {
        event.preventDefault();
        if (formData.cashMovementTypeId === '1') {
            setAuthShow(true);
        } else {
            await submit(null, null);
        }
    }

    const continueSubmit = async (data) => {
        await submit(data.email, data.password);
    }

    const submit = async (email, password) => {       

        if (!validate()) {
            Notify.send(`Debes seleccionar el tipo de movimiento e ingresar el monto de la transacción`, 'error');
            return false;
        }
            
        const data = {
            movement: {
                sessionId: parseInt(localStorage.getItem('pos_sessionId')),
                cashMovementTypeId: parseInt(formData.cashMovementTypeId),
                amount: parseFloat(formData.amount),
                notes: formData.notes
            },
            user: email,
            password: password
        }

        try {
            setSending(true);
            await SessionService.saveMovement(data);
            updateStatus();
            setFormData({});
            Notify.send(`Bien! Movimiento registrado exitosamente!`, 'success');
        }
        catch (err) {
            Notify.send(`Hubo un problema: ${err}`, 'error');
        }
        setSending(false);
    }

    const formControl = () => {

        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="mb-4 header-title">Nueva Transacción</h4>
                    <form onSubmit={preSubmit}>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Efectivo Caja</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" value={CurrencyFormat(status.currentCash)} readOnly={true} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Tipo Movimiento</label>
                            <div className="col-sm-8">
                                <select className="form-control" onChange={handleInputChange} name="cashMovementTypeId">
                                    <option value="">Selecciona...</option>
                                    {typesMovements().map((type, i) => <option key={i} value={type.id}>{type.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Monto</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" onChange={handleInputChange} name="amount" placeholder="$0" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Notas</label>
                            <div className="col-sm-8">
                                <textarea className="form-control" name="notes" onChange={handleInputChange} placeholder="Ej. se retira efectivo del día"></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">
                                <button type="submit" className="btn btn-success" disabled={sending}><FiCheck /> {sending ? 'Enviando...' : 'Confirmar'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    const lastMovements = () => {
        const initDate = moment(status.initDate);
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="mb-4 header-title">Movimientos de Efectivo</h5>
                    <p>Desde {initDate.format('DD/MM/YYYY')} a las {initDate.format('HH:mm')} hasta ahora</p>
                    <table className="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Movimiento</th>
                                <th>Usuario</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status.movements !== null ? 
                            (status.movements.map((move, i) => {
                                const movementType = movementTypes.find(t => t.id === move.cashMovementTypeId);
                                return (
                                    <tr key={i}>
                                        <td>{moment(move.movementDate).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{movementType.name}</td>
                                        <td>{move.userName}</td>
                                        <td>{CurrencyFormat(move.amount)}</td>
                                    </tr>
                                );
                            }))
                            :
                            (<tr><td colSpan={4} className="text-center">No hay movimientos de efectivo</td></tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    const showData = () => {
        return(
            <React.Fragment>
                <h5 className="mb-3"><MdMonetizationOn /> Control de Efectivo</h5>
                <div className="row">
                    <div className="col-md-6">
                        {formControl()}
                    </div>
                    <div className="col-md-6">
                        {lastMovements()}
                    </div>
                </div>
                <Authenticator show={authShow} continueSubmit={continueSubmit} onHide={() => setAuthShow(false)} />
            </React.Fragment>
        );
    }
    
    return (
        <React.Fragment>
            {loading ? <SpinnerLoader /> : showData()}
        </React.Fragment>
    );
}

export default CashManagement;