import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import SessionService from './SessionService';
import SubsidiariesService from '../Subsidiaries/SubsidiariesServices';
import * as Notify from '../../Utils/Notify';

import { MdLock, MdArrowForward } from 'react-icons/md';
import Logo from '../../Images/groove-logo.png';

const Login = () => {
    
    const [showLogin, setShowLogin] = useState(true);
    const [showCompanies, setShowCompanies] = useState(false);
    const [showSubsidiaries, setShowSubsidiaries] = useState(false);

    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({});

    const [userData, setUserData] = useState({});
    const [subsidiaries, setSubsidiaries] = useState({});

    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);

    const history = useHistory();

    useEffect(() => {
        localStorage.clear();
    }, []);
    
    const handleInputChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value});        
    }

    const submitLogin = async () => {
        const { email, password } = inputs;
        if (typeof email === 'undefined' && typeof password === 'undefined') {
            Notify.send('Debes ingresar correo y contraseña válidos', 'error');
            return false;
        }

        try {
            setLoading(true);
            const loginRes = await SessionService.login(email, password).catch(e => { throw e });
            const { companies } = loginRes.data;
            setUserData(loginRes.data);     
            setCompanies(companies);       
            localStorage.setItem('pos_token', loginRes.data.token);

            if (companies.length > 1) {
                setShowLogin(false);
                setShowCompanies(true);
            } else { 
                setCompanyId(companies[0].id);
                localStorage.setItem('pos_tenantId', companies[0].taxId);
                const subsidiaries = await SubsidiariesService.getAll();
                if (subsidiaries.data.length > 0) {
                    setSubsidiaries(subsidiaries.data);
                    setShowCompanies(false);
                    setShowLogin(false);
                    setShowSubsidiaries(true);
                } else {
                    await configSessionAndGo(loginRes.data, companies[0].taxId, 1);
                }
            }
            setLoading(false);   
        }
        catch (err) {
            console.error(err)
            const message = 'Hubo un problema al intentar acceder';
            Notify.clearAll();
            Notify.send(message, 'error');
        }
        setLoading(false);
    }

    const configSessionAndGo = async (userData, taxId, subsidiaryId) => {
        setLoading(true);
        console.log(userData);
        localStorage.setItem('pos_userName', userData.user.name);
        localStorage.setItem('pos_tenantId', taxId);
        localStorage.setItem('pos_token', userData.token);
        localStorage.setItem('pos_subsidiary', subsidiaryId);
        setTimeout(async () => {
            const sessionRes = await SessionService.getSession(userData.user.id);
            const { id } = sessionRes.data;
            localStorage.setItem('pos_sessionId', id);
            //setLoading(false);
            history.push('/');
        }, 3000);
    }

    async function setCompany(comId) {
        setCompanyId(companyId);
        const { taxId } = companies.find(t => t.id === comId);
        
        // get subisidaries
        localStorage.setItem('pos_tenantId', taxId);
        const subsidiaries = await SubsidiariesService.getAll();
        if (subsidiaries.data.length > 0) {
            setSubsidiaries(subsidiaries.data);
            setShowCompanies(false);
            setShowSubsidiaries(true);
        } else {
            await configSessionAndGo(userData, taxId, 1);
        }
    }

    const setSubsidiary = async (subsidiaryId) => {
        const taxId = localStorage.getItem('pos_tenantId');
        await configSessionAndGo(userData, taxId, subsidiaryId);
    }

    const formLogin = () => {
        return(
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center mt-2 mb-2">
                        <img src={Logo} alt="logo" width="70" />
                    </h3>
                    <div className="p-3">
                        <h5 className="text-muted font-18 m-b-5 text-center">Bienvenido!</h5>
                        <p className="text-muted text-center">Acceso a POS Groove.</p>
                        <div className="form-horizontal m-t-30">
                            <div className="form-group">
                                <label>Usuario</label>
                                <input type="email" className="form-control" name="email" onChange={handleInputChange} placeholder="Correo Electrónico" autoFocus={true} />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" className="form-control" name="password" onChange={handleInputChange} placeholder="Contraseña" />
                            </div>
                            <div className="form-group row mt-3 text-center">
                                <div className="col-12">
                                    <button className="btn btn-primary w-md" onClick={submitLogin} type="button" disabled={loading}><MdArrowForward/> Acceder</button>
                                </div>                                
                            </div>
                            <div className="form-group mb-0 row">
                                <div className="col-12 text-center">
                                    <Link className="text-muted" to="/recovery" disabled={loading}><MdLock/> No recuerdo mi contraseña</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const formCompany = () => {
        return(
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center mt-2 mb-2">
                        <img src={Logo} alt="logo" width="70" />
                    </h3>
                    <div className="p-3">
                        <h4 className="text-muted font-18 m-b-5 text-center">Selecciona Empresa</h4>
                        <p className="text-muted text-center">¿Con cual deseas trabajar?</p>
                        <div className="m-t-30" style={{ display: loading ? 'none' : 'block' }}>
                            <table className="table table-hover text-left pointer-tr">
                                <thead>
                                    <tr>
                                        <th>Rut</th>
                                        <th>Razón Social</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {companies.map((com, index) => {
                                        return (
                                            <tr key={index} onClick={() => setCompany(com.id) }> 
                                                <td>{com.taxId}</td>
                                                <td>{com.bussinesName}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>                            
                        </div>
                        {loading ? (<p className="m-4 text-center">Cargando...</p>) : ('') }
                    </div>
                </div>
            </div>
        );
    }

    const formSubsidiary = () => {
        return(
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center mt-2 mb-2">
                        <img src={Logo} alt="logo" width="70" />
                    </h3>
                    <div className="p-3">
                        <h4 className="text-muted font-18 m-b-5 text-center">Selecciona Sucursal</h4>
                        <p className="text-muted text-center">¿Con cual deseas trabajar?</p>
                        <div className="m-t-30" style={{ display: loading ? 'none' : 'block' }}>
                            <table className="table table-hover text-left pointer-tr">
                                <tbody>
                                    {subsidiaries.map((sub, index) => {
                                        return (
                                            <tr key={index} onClick={() => setSubsidiary(sub.id) }> 
                                                <td>
                                                    <span className="text-proper">{sub.name}</span>
                                                    <small className="text-muted">{sub.address}</small>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>                            
                        </div>
                        {loading ? (<p className="m-4 text-center">Cargando...</p>) : ('') }
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="wrapper-page">
            {showLogin && formLogin()}
            {showCompanies && formCompany()}
            {showSubsidiaries && formSubsidiary()}
        </div>
    );
}

export default Login;