import React, { useContext, useState } from 'react';
import { PosContext } from  '../../Contexts/PosContext';
import { FiPlus, FiMinus, FiEdit } from 'react-icons/fi';

import SaleItemNote from './SaleItemNotes';
import CurrencyFormat from '../../Utils/CurrencyFormat';
import * as Notify from '../../Utils/Notify';

const SaleItems = props => {    

    const { saleItems, setSaleItems } = useContext(PosContext);

    const [itemNoteShow, setItemNoteShow] = useState(false);
    const [itemNote, setItemNote] = useState({});

    const addItem = (id) => {
        let findItem = saleItems.find(t => t.id === id);
        findItem.quantity = parseFloat(findItem.quantity) + 1;
        findItem.subtotal = findItem.sellingRate * findItem.quantity;
        setSaleItems(saleItems.map(a => a.id === id ? { ...a, findItem } : a));
        props.focusBarCode();
    }

    const removeItem = (id) => {
        let findItem = saleItems.find(t => t.id === id);
        findItem.quantity = parseFloat(findItem.quantity) - 1;
        findItem.subtotal = findItem.sellingRate * findItem.quantity;

        if (findItem.quantity <= 0) {
            setSaleItems(saleItems.filter(a => a.id !== id));
        } else {
            setSaleItems(saleItems.map(a => a.id === id ? { ...a, findItem } : a));
        }
        props.focusBarCode();
    }

    function updateNote(item) {
        setSaleItems(saleItems.map(a => a.id === item.id ? { ...a, item } : a));
    }

    function editNote(id) {
        setItemNote(saleItems.find(t => t.id === id));
        setItemNoteShow(true);
    }

    function editQuantity(event) {
        Notify.clearAll();
        const id = parseInt(event.target.id.split('-')[1]);
        //const value = event.target.value.length > 0 ? event.target.value: 0;
        const value = event.target.value;

        if (value <= 0) {            
            Notify.send(`La cantidad debe ser superior a 0 (cero)`, 'warning');
        }

        let findItem = saleItems.find(t => t.id === id);
        findItem.quantity = value;
        findItem.subtotal = findItem.sellingRate * parseFloat(findItem.quantity);
        setSaleItems(saleItems.map(a => a.id === id ? { ...a, findItem } : a));  
        
        props.focusBarCode();
    }

    /*function resetQuantity(event) {
        const value = event.target.value;
        const id = parseInt(event.target.id.split('-')[1]);
   


        let findItem = saleItems.find(t => t.id === id);
        findItem.quantity = value;
        findItem.subtotal = findItem.sellingRate * parseFloat(findItem.quantity);
        setSaleItems(saleItems.map(a => a.id === id ? { ...a, findItem } : a)); 

        if (event.target.value.length > 0 ||  ? event.target.value: 1;
    }*/

    const showItems = (itemsList) => {
        console.log(itemsList);
        if (itemsList.length > 0) {            
            return (
                <>
                {itemsList.map((i, index) =>
                    <tr key={index} className="align-middle">
                        <td>
                            <div className="btn-group" role="group">
                                <button onClick={() => removeItem(i.id)} type="button" className="btn btn-secondary"><FiMinus /></button>
                                <button onClick={() => addItem(i.id)} type="button" className="btn btn-secondary"><FiPlus /></button>
                            </div>
                            <button onClick={() => editNote(i.id)} type="button" className="btn btn-secondary ml-2"><FiEdit /></button>
                        </td>
                        <td>{i.name}</td>
                        <td>{CurrencyFormat(i.sellingRate)}</td>
                        <td className="text-center">
                            {i.unitShortName != 'un' ? <input type="text" className="editable-quantity" id={'item-' + i.id} value={i.quantity} onChange={editQuantity} /> : <span className="no-editable-quantity">{i.quantity}</span>} 
                            {' ' + i.unitShortName}
                        </td>
                        <td>{CurrencyFormat(i.sellingRate * i.quantity)}</td>
                    </tr>
                )}
                </>
            );
        }
    }

    return(
        <div className="card" style={{ minHeight: '400px' }}>
            <div className="table-responsive">
                <table className="table table-lg no-borders table-striped">
                    <thead className="no-border-shadow">
                        <tr>
                            <th style={{width: '19%'}}></th>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th className="text-center" style={{width: '25%'}}>Cantidad</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showItems(saleItems)} 
                    </tbody>
                </table>
            </div>   
            <SaleItemNote show={itemNoteShow} item={itemNote} updateNote={updateNote} onHide={() => setItemNoteShow(false)} />    
        </div>        
    );
}

export default SaleItems;