import React, { useEffect, useState } from 'react';

import ItemsServices from '../Items/ItemsServices';
import PaymentsServices from '../Payments/PaymentsServices';
import DteServices from '../Folios/DteServices';
import { PosContext } from '../../Contexts/PosContext';

import BarcodeSearcher from '../BarcodeSearcher/BarcodeSearcher';
import ItemSearcher from './ItemSearcher';
import Pay from './Pay';
import Totalizer from './Totalizer';
import SaleItems from './SaleItems';

import * as Notify from '../../Utils/Notify';
import { FiSearch, FiDollarSign, FiSettings } from 'react-icons/fi';
import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import SpinnerLoader from '../Layout/SpinnerLoader';

const Pos = () => {

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [typeDocuments, setTypeDocuments] = useState([]);
    const [saleItems, setSaleItems] = useState([]);
    const [barFocus, setBarFocus] = useState(true);
    const [itemSearchShow, setItemSearchShow] = useState(false);
    const [payShow, setPayShow] = useState(false);
    const [typeDoc, setTypeDoc] = useState(39);
    const [loadingPay, setLoadingPay] = useState(false);
    const [printConnection, setPrintConnection] = useState(null);

    useEffect(() => {
        async function fetchData() {           
            const itemsRes = await ItemsServices.getAll();
            setItems(itemsRes.data);

            const paymentMethodRes = await PaymentsServices.getMethods();
            setPaymentMethods(paymentMethodRes.data);

            const typeDocuments = await PaymentsServices.getTypeDocuments();
            const typeDocumentsActive = typeDocuments.data.filter(t => t.id === 25 || t.id === 39 && t.active === 1);
            //const typeDocumentsActive = typeDocuments.data.filter(t => t.id === 39 && t.active === 1);

            checkDocumentFolios();

            setTypeDocuments(typeDocumentsActive);
            setLoading(false);
        }
        fetchData(); 

        const printHub = new HubConnectionBuilder()
                .withUrl('http://localhost:5000/print')
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build();
        setPrintConnection(printHub);
    }, []);

    useEffect(() => {
        if (printConnection) {
            printConnection.start()
                .then(result => {
                    console.log('Print Connected!');
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [printConnection]);


    const sendPrint = async (data) => {
        const printMessage = {
            documentName: data.documentName,            
            taxId: data.taxId,
            name: data.name, 
            businessName: data.businessName,
            economicActivity: data.economicActivity,
            address: data.address,
            documentNumber: data.sale.documentNumber.toString(),
            emissionDate: data.sale.emissionDate,
            totalAmount: data.sale.totalAmount.toString(),
            totalNet: data.sale.totalNet.toString(),
            totalTax: data.sale.totalTax.toString(),
            totalExempt: data.sale.totalExempt.toString(),
            detail: data.detail.map(det => ({ itemName: det.itemName, quantity: det.quantity.toString(), subtotal: det.subtotal.toString()}) ),
            stamp: data.stamp
        }

        if (printConnection.connectionStarted) {
            try {
                console.log('Print order sended');
                console.log(await printConnection.send('SendPrint', printMessage));
                //console.log(await printConnection.send('SendPrint', 'POS80', '58', printMessage));
            }
            catch(e) {
                console.log(e);
            }
        } else {
            console.log('No connection to print server yet');
        }
    }

    const setTypeDocument = (event) => {
        const id = parseInt(event.target.value);
        setTypeDoc(id);
    }    

    const checkDocumentFolios = async () => {        
        const folioRes = await DteServices.checkFoliosDocument(typeDoc);
        if (!folioRes.data) {
            Notify.clearAll();    
            Notify.send('El documento seleccionado no tiene folios', 'error');
            setTypeDoc(25);
            return false;
        }
        return true;
    }

    const initPay = async () => {
        setLoadingPay(true);
        if (saleItems.length > 0 && await checkDocumentFolios()) {
            setPayShow(true)
        }
        setLoadingPay(false);
    }

    function focusBarCode() {
        setBarFocus(true);
    }

    const PosUI = () => {
        return (
            <PosContext.Provider value={{ items, paymentMethods, typeDoc, saleItems, setSaleItems, sendPrint }}>
                <div className="row">
                    <div className="col-md-9">
                        <BarcodeSearcher focus={barFocus} />
                        <div className="row">
                            <div className="col-12">
                                <SaleItems focusBarCode={focusBarCode} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <Totalizer />
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <button onClick={() => setItemSearchShow(true)} type="button" className="btn btn-block btn-secondary"><FiSearch /> Buscar Productos</button>
                                <button onClick={() => initPay()} type="button" className="btn btn-lg btn-block btn-success" disabled={loadingPay}><FiDollarSign /> Pagar</button>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <label><FiSettings /> Configuración</label>
                                <select name="documentTypeId" value={typeDoc} onChange={setTypeDocument} className="form-control">
                                    {typeDocuments.map((type, i) => <option value={type.id} key={i}>{type.name}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <ItemSearcher show={itemSearchShow} focus={true} onHide={() => { setItemSearchShow(false); focusBarCode(); }} />
                <Pay show={payShow} payMethods={paymentMethods} onHide={() => setPayShow(false)} onHidePrint={() => { setPayShow(false); }} />
            </PosContext.Provider>
        );
    }

    return (
        loading ? <SpinnerLoader /> : (PosUI())
    );
}

export default Pos;