import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FiCheck } from 'react-icons/fi';
import * as Notify from '../../Utils/Notify';

const Authenticator = props => {

    const [inputs, setInputs] = useState({});

    const handleInputChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value});        
    }

    const continueAuth = () => {
        if (typeof inputs.email !== 'undefined' && typeof inputs.password !== 'undefined') {
            props.continueSubmit(inputs);
            props.onHide();
        } else {
            Notify.send(`Debes ingresar un usuario y password válido`, 'error');
        }
    }

    const cancelAuth = () => {
        setInputs({});
        props.onHide();
    }

    return(
        <Modal show={props.show} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    Autenticación
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pl-3 pr-3">
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" name="email" onChange={handleInputChange} className="form-control" placeholder="tu@correo.com" />
                    </div>
                    <div className="form-group">
                        <label>Contraseña</label>
                        <input type="password" name="password" onChange={handleInputChange} className="form-control" placeholder="contraseña" />
                    </div>                    
                </div>                
            </Modal.Body>
            <Modal.Footer>                
                <button type="button" onClick={() => cancelAuth()} className="btn btn-secondary">Cancelar</button>
                <button type="button" onClick={() => continueAuth()} className="btn btn-primary"><FiCheck />Continuar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default Authenticator;