import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SessionService from '../Login/SessionService';
import * as Notify from '../../Utils/Notify';
import SpinnerLoader from './SpinnerLoader';

const Private = props => {

    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const validateJwt = async () => {
            try {
                const _token = localStorage.getItem('pos_token');
                if (!_token) {
                    history.push('/login');
                } else {
                    // validate token
                    const tokenValidate = { token: _token };
                    const response = await SessionService.validate(tokenValidate);
                    if (!response.data) {
                        history.push('/login');
                        Notify.clearAll();
                        Notify.send('Atención! Tu sesión ha caducado', 'error');
                    }
                }
            }
            catch (error) {
                history.push('/login');
                return;
            }
            setLoading(false);
        };
        validateJwt();
        setLoading(false);
    }, [history]);    

    return(loading ? <SpinnerLoader /> : props.children);
}

export default Private;