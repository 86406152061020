import API from '../../Utils/HttpCommon';

const save = data => {
    return API.post(`sales`, data);
}

const get = id => {
    return API.get(`sales/${id}`);
}

export default { save, get }