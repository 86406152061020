import axios from 'axios';

const API = axios.create({
    baseURL: `${process.env.REACT_APP_API_GROOVE}`,
    headers: { 'Content-Type': 'application/json' }
});

API.interceptors.request.use(
    request => {
        if (!request.url.includes('auth')) {
            request.headers['authorization'] = `Bearer ${localStorage.getItem('pos_token')}`;
            request.headers['tenantId'] = localStorage.getItem('pos_tenantId');
        }
        return request;
    }
);

export default API;