import React from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { MdAccountCircle, MdPowerSettingsNew } from 'react-icons/md';

import Logo from '../../Images/small-logo.png';
import UserDefault from '../../Images/default-user.jpg';

const Header = props => {    

    const userName = localStorage.getItem('pos_userName');

    return(
        <nav className="navbar navbar-expand-md navbar-dark bg-top fixed-top">
            <div className="navbar-brand"><img src={Logo} width="30px" alt="GroovePOS" /> GroovePOS</div>

            <button onClick={() => props.setShowSidebar(props.show ? false : true)} className="btn btn-dark"><FiMenu /></button>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown profile-dropdown">
                    <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                        <img src={UserDefault} alt="user" className="rounded-circle" /> 
                        <span className="user-text">{userName} </span>
                    </span>                    
                    <div className="dropdown-menu dropdown-menu-right dropdown-info">
                        <Link className="dropdown-item" to="/profile"><MdAccountCircle /> Mi Perfil</Link>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item text-danger" onClick={() => props.setShowFinish(true)}><MdPowerSettingsNew /> Cerrar Turno</button>
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default Header;