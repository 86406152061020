import React, { useState, useEffect } from 'react';
import SpinnerLoader from '../Layout/SpinnerLoader';
import { FiPlus } from 'react-icons/fi';
import RefundModal from './RefundModal';
import RefundSevices from './RefundServices';

const RefundList = () => {

    const [loading, setLoading] = useState(true);
    const [refunds, setRefunds] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const posId = parseInt(localStorage.getItem('pos_sessionId'));
            const response = await RefundSevices.getAllByPos(posId).catch(e => console.log(e));
            setRefunds(response.data);
            setLoading(false);
        };
        fetchData();     
    }, []);

    function render() {
        return(
            <React.Fragment>
                <h5 className="mb-3">
                    Devoluciones
                </h5>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div class="col-md-12 text-right">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowModal(true)}><FiPlus /> Nueva Devolución</button>
                                    </div>
                                </div>
                                <div className="table-responsive mt-3">
                                    <table className="table table-sm table-striped">
                                        <thead>
                                            <tr>
                                                <th>Folio</th>
                                                <th>Tipo Documento</th>
                                                <th>Tipo Devolución</th>
                                                <th>Fecha</th>
                                                <th>Monto</th>
                                                <th>Usuario</th>
                                                <th>Comentarios</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {refunds.length > 0 ? 
                                            (refunds.map((ref, i) => {
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            })) 
                                            :
                                            (<tr><td class="text-center" colSpan={7}>No hay devoluciones registradas</td></tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>                        
                    </div>                   
                </div>
                <RefundModal show={showModal} onHide={() => { setShowModal(false) }}  />
            </React.Fragment>
        ); 
    }

    return loading ? (<SpinnerLoader />) : (render());
}

export default RefundList;