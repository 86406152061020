import React, { useState, useContext, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { PosContext } from '../../Contexts/PosContext';
import CurrencyFormat from '../../Utils/CurrencyFormat';

import * as Notify from '../../Utils/Notify';

const ItemSearcher = props => {

    const inputRef = useRef(null);
    const [needle, setNeedle] = useState('');
    const [findedItems, setFindedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { items, saleItems, setSaleItems } = useContext(PosContext);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            focusInputBar();
        }
    }, [props]);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setNeedle(value);
    }

    const handleKeyUp = async (event) => {
        const { value } = event.target;
        if (value.length > 0) {
            if (event.keyCode === 13) {    
                await searchBy(value);
            }
        }
    }

    const focusInputBar = () => {
        inputRef.current.focus();
    }

    const searchBy = async (value) => {   
        Notify.clearAll();    
        setLoading(true);        
        const lowerString = value.toLowerCase();
        const filteredItems = items.filter(item => item.name.toLowerCase().indexOf(lowerString) > -1);
        if (filteredItems.length > 0) {
            const onlySellables = filteredItems.filter(item => item.canSold === 1);
            setFindedItems(onlySellables);
            Notify.send(`Se han encontrado <strong>${onlySellables.length}</strong> productos`, 'info');
        } else {
            setFindedItems([]);
            Notify.send(`No se han encontrado productos`, 'warning');
        }       
        setLoading(false);
    }

    const selectItem = (id) => {
        const selectedItem = items.find(t => t.id === id);        
        const item = {
            id: selectedItem.id,
            code: selectedItem.barcode,
            name: selectedItem.name,
            quantity: 1,
            sellingRate: parseFloat(selectedItem.sellingRate),
            subtotal: selectedItem.sellingRate,
            canSold: selectedItem.canSold,
            canOrdered: selectedItem.canOrdered,
            isExempt: selectedItem.isExempt,
            taxPercent: selectedItem.taxPercent,
            unitName: selectedItem.unitName,
            unitShortName: selectedItem.unitShortName
        };
        if (saleItems.some(e => e.id === item.id)) {
            let findItem = saleItems.find(t => t.id === item.id);
            findItem.quantity += 1;
            findItem.subtotal = findItem.sellingRate * findItem.quantity;
            setSaleItems(saleItems.map(a => a.id === item.id ? { ...a, findItem } : a));
        } else {
            setSaleItems([ ...saleItems, item ]);
        }
        //setNeedle('');
        //setFindedItems([]);
        Notify.clearAll();
        Notify.send(`Se ha agregado <strong>${item.name}</strong> a la venta`, 'success');
        focusInputBar();
        //props.onHide();
    }

    const showItems = (findItems) => {
        return (
            <>
            {findItems.map((i, index) =>
                <tr key={index} onClick={() => selectItem(i.id)}>
                    <td>{i.barcode}</td>
                    <td>{i.name}</td>
                    <td>{CurrencyFormat(i.sellingRate)}</td>
                    <td>{i.unitShortName}</td>
                </tr>
            )}
            </>
        );
    }

    return (
        <Modal show={props.show} size="xl" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FiSearch /> Buscar Productos
                </Modal.Title>
                <button onClick={props.onHide} type="button" className="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '300px'}}>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Buscar</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" ref={inputRef} onKeyUp={handleKeyUp} onChange={handleInputChange} value={needle} />
                    </div>
                </div>
                <div className="table-resposive">
                    <table className="table table-hover table-sm pointer-tr">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Precio</th>
                                <th>Unidad Medida</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loading ? (<tr><td colSpan={3} className="text-center">Buscando...</td></tr>) : 
                        (                        
                            findedItems.length > 0 ? 
                            showItems(findedItems) 
                            : 
                            (<tr><td colSpan={3} className="text-center">No hay items para mostrar</td></tr>)
                        )}
                        </tbody>
                    </table>
                </div>                
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => {props.onHide(); Notify.clearAll();}} type="button" className="btn btn-secondary">Cerrar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ItemSearcher;