import Noty from 'noty';

export const send = (text, type) => {
    const options = {
        theme: 'bootstrap-v4',
        layout: 'bottom',
        text,
        type
    }
    const noty = new Noty(options);
    noty.setTimeout(7000);
    noty.show();
}

export const clearAll = () => {
    Noty.closeAll()
}