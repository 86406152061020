import API from '../../Utils/HttpCommon';

const login = async (email, pass) => {
    return await API.post(`auth/sign/pos?email=${email}&password=${pass}`);
}

const validate = async (data) => {
    const resp = await API.post(`auth/validate`, data);
    return resp;
}

const getSession = async (id) => {
    return await API.get(`pos/session/user/${id}`);
}

const getStatus = async (id) => {
    return await API.get(`pos/session/status/${id}`);
}

const saveMovement = async (data) => {
    return await API.post(`pos/movement/cash`, data);
}

const closeSession = async (id) => {
    return await API.post(`pos/session/close/${id}`, null);
}

const getCurrentUser = async () => {
    return await API.get(`users/current`);
}

export default { getSession, getStatus, saveMovement, closeSession, validate, login, getCurrentUser }