import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { MdRedo } from 'react-icons/md';
import SpinnerLoader from '../Layout/SpinnerLoader';

import SessionService from '../Login/SessionService';
import CurrencyFormat from '../../Utils/CurrencyFormat';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SaleModal from '../Sales/SaleModal';

const Movements = () => {

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState({});    

    const [currentSale, setCurrentSale] = useState(0);
    const [showSale, setShowSale] = useState(false);

    const movementTypes = [
        { id: 1, name: 'Retiro' },
        { id: 2, name: 'Saldo Apertura' },
        { id: 3, name: 'Otros Ingresos' }
    ];

    const documentTypes = [
        { id: 25, name: 'Nota de Venta' },
        { id: 26, name: 'Nota de Venta Exenta' },
        { id: 33, name: 'Factura Electrónica' },
        { id: 34, name: 'Factura Electrónica Exenta' },
        { id: 39, name: 'Boleta Electrónica' },
        { id: 41, name: 'Boleta Electrónica Exenta'}
    ];

    useEffect(() => {
        const fetchStatus = async () => {
            const id = parseInt(localStorage.getItem('pos_sessionId'));
            const tenant = parseInt(localStorage.getItem('pos_tenantId'));
            const response = await SessionService.getStatus(id, tenant).catch(e => console.log(e));
            setStatus(response.data);
            setLoading(false);
        }
        fetchStatus();
    }, []);
    
    const paymentsByMethod = () => {
        let paymentsByMethod = [];   
        Object.entries(status.totalByMethod).forEach((item, i) => paymentsByMethod.push(  
            <div className="card mini-stat bkg-primary" key={i}>
                <div className="card-body mini-stat-img">
                    <div className="mini-stat-icon">
                        <i className="mdi mdi-cube-outline float-right"></i>
                    </div>
                    <div className="text-white">
                        <h6 className="text-uppercase mb-3">{item[0]}</h6>
                        <h4 className="mb-4">{CurrencyFormat(item[1])}</h4>
                    </div>
                </div>
            </div>
        ));
        return paymentsByMethod;
    }

    const totalCash = () => {
        return (
            <div className="card mini-stat bkg-success">
                <div className="card-body mini-stat-img">
                    <div className="mini-stat-icon">
                        <i className="mdi mdi-cube-outline float-right"></i>
                    </div>
                    <div className="text-white">
                        <h6 className="text-uppercase mb-3">Efectivo en Caja</h6>
                        <h4 className="mb-0">{CurrencyFormat(status.currentCash)}</h4>
                        <span className="badge badge-warning">{CurrencyFormat(status.totalChanges)}</span> 
                        <span className="ml-2">Monto en Vueltos</span>
                    </div>
                </div>
            </div>
        );
    }

    function folioFormatter(cell) {        
        const currVal = cell.split('-');
        return <a href="#" onClick={() => { setCurrentSale(parseInt(currVal[0])); setShowSale(true) }} >{currVal[1]}</a>
    }

    function dateFormatter(cell) {
        return moment(cell).format('DD/MM/YYYY HH:mm')
    }

    function amountFormatter(cell) {
        return CurrencyFormat(cell)
    }

    const lastSales = () => {

        const columns = [
            {
                dataField: 'numberId',
                text: 'Folio',
                formatter: folioFormatter
            },
            {
                dataField: 'docTypeName',
                text: 'Tipo'
            },
            {
                dataField: 'emissionDate',
                text: 'Fecha',
                formatter: dateFormatter
            },
            {
                dataField: 'totalAmount',
                text: 'Monto',
                headerClasses: 'text-right',
                classes: 'text-right',
                formatter: amountFormatter
            },
        ];

        const sales = status.lastSales.map((sale) => {            
            sale.numberId = sale.id + '-' + sale.documentNumber;
            sale.docTypeName = documentTypes.find(t => t.id === sale.documentTypeId).name;
            return sale;
        });

        return(
            <div className="card">
                <div className="card-body">
                    <h4 className="mb-4 header-title">Registro de Ventas</h4>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>Total Ventas Turno</th>
                                <th className="text-right">{CurrencyFormat(status.totalAmountSales)}</th>
                            </tr>
                        </tbody>   
                    </table>
                    {(sales.length > 0) 
                    ? 
                    (<BootstrapTable
                        bootstrap4
                        className="table-sm"
                        keyField="id"
                        data={sales}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 10 })}
                        bordered={false}
                        striped={true}
                        condensed={true}
                    />) 
                    :
                    (<div className="text-center p-4">
                        <p>No hay ventas registradas en el turno</p>
                    </div>)}
                </div>
            </div>
        );
    }

    const cashMovements = () => {
        return(
            <div className="card">
                <div className="card-body">
                    <h4 className="mb-4 header-title">Movimientos de Efectivo</h4>
                    <table className="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Movimiento</th>
                                <th>Usuario</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status.movements !== null ? 
                            (status.movements.map((move, i) => {
                                const movementType = movementTypes.find(t => t.id === move.cashMovementTypeId);
                                return (
                                    <tr key={i}>
                                        <td>{moment(move.movementDate).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{movementType.name}</td>
                                        <td>{move.userName}</td>
                                        <td>{CurrencyFormat(move.amount)}</td>
                                    </tr>
                                );
                            }))
                            :
                            (<tr><td colSpan={4} className="text-center">No hay movimientos de efectivo</td></tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    const showData = () => {        
        const initDate = moment(status.initDate);
        return(
            <React.Fragment>
                <h5 className="mb-3">
                    <MdRedo /> Movimientos <br />
                    <small className="text-muted font-14">Inicio Turno {initDate.format('DD/MM/YYYY')} a las {initDate.format('HH:mm')}</small>
                </h5>
                <div className="row mt-3">
                    <div className="col-md-3">
                        {totalCash()}
                        {paymentsByMethod()}
                    </div>
                    <div className="col-md-9">
                        {lastSales()}
                        {cashMovements()}
                    </div>                    
                </div>
                <SaleModal show={showSale} saleId={currentSale} onHide={() => { setShowSale(false) }}  />
            </React.Fragment>
        );
    }

    return(
        <React.Fragment>
            {loading ? <SpinnerLoader /> : showData()}
        </React.Fragment>
    );
}

export default Movements;