import API from '../../Utils/HttpCommon';

const getMethods = () => {
    return API.get(`payments/methods`);
}

const getTypeDocuments = () => {
    return API.get(`payments/typedocuments`);
}

export default { getMethods, getTypeDocuments }