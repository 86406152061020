import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import SessionService from '../Login/SessionService';
import CurrencyFormat from '../../Utils/CurrencyFormat';

const Finish = props => {

    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [status, setStatus] = useState({});
    const history = useHistory();
    
    useEffect(() => {
        async function getCurrentStatus() {
            const id = parseInt(localStorage.getItem('pos_sessionId'));
            const response = await SessionService.getStatus(id).catch(e => console.log(e));
            setStatus(response.data);
            setLoading(false);
        }
        setLoading(true);
        if (props.show) {
            setTimeout(() => {
                getCurrentStatus();
            }, 500);            
        }
    }, [props]);

    const goToCashManagement = () => {
        props.onHide();
        history.push('/management');
    }

    const finishSession = async () => {
        setSending(true);
        const id = parseInt(localStorage.getItem('pos_sessionId'));
        await SessionService.closeSession(id).catch(e => console.log(e));
        props.onHide();
        setSending(false);
        history.push('/login');
    }
    
    return(
        <Modal show={props.show} backdrop="static"> 
            <Modal.Header>
                <Modal.Title>
                    Terminar Turno
                </Modal.Title>
                <button onClick={props.onHide} type="button" className="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body className="text-center" style={{ minHeight: '100px'}}>
                {loading ? ('Cargando...') : 
                (status.currentCash > 0 ? 
                (<p>Actualmente tienes <strong>{CurrencyFormat(status.currentCash)}</strong> efectivo 
                    en Caja. Primero debes retirarlos antes de finalizar el turno.</p>
                )
                :
                (<p>¿Estas seguro que deseas finalizar este turno?</p>))}
            </Modal.Body>
            {!loading ? (
            <Modal.Footer>                
                {status.currentCash > 0 ? 
                (<button type="button" onClick={() => goToCashManagement()} className="btn btn-secondary">Ir al Control de Efectivo</button>)
                :
                (<button onClick={() => finishSession()} type="button" className="btn btn-danger" disabled={sending}>Terminar Turno</button>)
                }       
            </Modal.Footer>)
            :
            ('')}
        </Modal>
    );
}

export default Finish;