import React, { useContext } from 'react';
import { PosContext } from '../../Contexts/PosContext';
import CurrencyFormat from '../../Utils/CurrencyFormat';

const Totalizer = () => {

    const { saleItems } = useContext(PosContext);

    const Total = () => {
        //saleItems.map(item => console.log(item));
        const getTotal = saleItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
        
        return CurrencyFormat(getTotal);
    }

    return(
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6"><h6>Subtotal</h6></div>
                    <div className="col-md-6 text-right"><h6>0</h6></div>
                </div>
                <div className="row">
                    <div className="col-md-6"><h6>Descuentos</h6></div>
                    <div className="col-md-6 text-right"><h6>0</h6></div>
                </div>
                <div className="row">
                    <div className="col-md-6"><h5>Total</h5></div>
                    <div className="col-md-6 text-right"><h5><Total /></h5></div>
                </div>
            </div>
        </div>
    );
}

export default Totalizer;