import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FiSearch, FiCheck } from 'react-icons/fi';
import SpinnerLoader from '../Layout/SpinnerLoader';
import moment from 'moment';
import CurrencyFormat from '../../Utils/CurrencyFormat';

import RefundServices from './RefundServices';

const SaleModal = (props) => {

    const [loading, setLoading] = useState(true);
    const [refundHeader, setRefundHeader] = useState(null);
    const [refundDetail, setRefundDetail] = useState(null);

    useEffect(() => {        
     
    }, [])

    const handleInputChange = (event) => {
        setRefundHeader({...refundHeader, [event.target.name]: event.target.value});        
    }

    const handleInputDetail = (i, event) => {
        setRefundDetail({...refundDetail[i], [event.target.name]: event.target.value});        
    }

    const typesMovements = () => {
        return [
            { id: 1, name: 'Devolución de Producto' },
            { id: 2, name: 'Cambio de Producto' }
        ]
    }

    const documentTypes = () => {
        return [
            { id: 25, name: 'Nota de Venta' }
        ]
    }

    const submit = async () => {       

       /* if (!validate()) {
            Notify.send(`Debes seleccionar el tipo de movimiento e ingresar el monto de la transacción`, 'error');
            return false;
        }
            
        const data = {
            
        }

        try {
            setSending(true);
            await SessionService.saveMovement(data);
            updateStatus();
            setFormData({});
            Notify.send(`Bien! Movimiento registrado exitosamente!`, 'success');
        }
        catch (err) {
            Notify.send(`Hubo un problema: ${err}`, 'error');
        }
        setSending(false);*/
    }

    return (
        <Modal show={props.show} size="lg" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FiSearch /> Ingresar Devolución
                </Modal.Title>
                <button onClick={props.onHide} type="button" className="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '300px'}}>
                <form onSubmit={submit}>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Tipo Movimiento</label>
                        <div className="col-sm-8">
                            <select className="form-control" onChange={handleInputChange} name="refundTypeId">
                                <option value="">Selecciona...</option>
                                {typesMovements().map((type, i) => <option key={i} value={type.id}>{type.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Tipo Documento</label>
                        <div className="col-sm-8">
                            <select className="form-control" onChange={handleInputChange} name="documentTypeId">
                                <option value="">Selecciona...</option>
                                {documentTypes().map((type, i) => <option key={i} value={type.id}>{type.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Folio Documento</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" onChange={handleInputChange} name="documentNumber" placeholder="ej. 12" />
                        </div>
                    </div>                    
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Razón</label>
                        <div className="col-sm-8">
                            <textarea className="form-control" name="reason" onChange={handleInputChange} placeholder=""></textarea>
                        </div>
                    </div>
                </form>                               
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary"><FiCheck /> Confirmar</button>
                <button type="button" onClick={() => { props.onHide() }} className="btn btn-secondary">Cerrar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default SaleModal;