import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './Components/Login/Login';
import Private from './Components/Layout/Private';
import Header from './Components/Layout/Header';
import Sidebar from './Components/Layout/Sidebar';
import Pos from './Components/Pos/Pos';
import Movements from './Components/Movements/Movements';
import CashManagement from './Components/CashManagement/CashManagement';
import Profile from './Components/User/Profile';
import Finish from './Components/Pos/Finish';
import RefundList from './Components/Refunds/RefundList';

import './Css/App.css';

const App = () => {

	const [showFinish, setShowFinish] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);

	return (
		<BrowserRouter>
            <Switch>
				<Route path="/login" component={Login} />
				<Private>
					<Header setShowSidebar={setShowSidebar} setShowFinish={setShowFinish} show={showSidebar} />
					<Sidebar setShowSidebar={setShowSidebar} show={showSidebar} />
					<main className="container-fluid" role="main">
						<Route exact path="/" component={Pos} />
						<Route path="/movements" component={Movements} />
						<Route path="/management" component={CashManagement} />
                        <Route path="/refunds" component={RefundList} />
						<Route path="/profile" component={Profile} />
					</main>
					<Finish show={showFinish} onHide={() => setShowFinish(false)} />
				</Private>
			</Switch>
		</BrowserRouter>
	);
}

export default App;