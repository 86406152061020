import API from '../../Utils/HttpCommon';

const save = data => {
    return API.post(`refunds`, data);
}

const get = id => {
    return API.get(`refunds/${id}`);
}

const getAllByPos = posId => {
    return API.get(`refunds/pos/${posId}`);
}

export default { save, get, getAllByPos }