import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { MdShoppingBasket, MdRedo, MdMonetizationOn, MdRequestPage } from 'react-icons/md'

import * as Notify from '../../Utils/Notify';

const Sidebar = props => {

    const hideSidebar = () => {
        Notify.clearAll();
        props.setShowSidebar(false);
    }

    return (
        <nav className={props.show ? 'sidebar-wrapper show' : 'sidebar-wrapper'}>
            <div className="sidebar-content">
                <div className="sidebar-menu">
                    <ul>
                        <li className="header-menu"><span>Punto de Venta</span></li>
                        <li><Link className="" to="/" onClick={() => hideSidebar()}><MdShoppingBasket/> Vender</Link></li>
                        <li><Link className="" to="/movements" onClick={() => hideSidebar()}><MdRedo/> Movimientos</Link></li>
                        <li><Link className="" to="/management" onClick={() => hideSidebar()}><MdMonetizationOn/> Control de Efectivo</Link></li>
                        <li><Link className="" to="/refunds" onClick={() => hideSidebar()}><MdRequestPage/> Devoluciones</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default withRouter(Sidebar);