import React, { useEffect, useState } from 'react';
import moment from 'moment';

import SpinnerLoader from '../Layout/SpinnerLoader';

import SessionService from '../Login/SessionService';

const Profile = () => {

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});    

    useEffect(() => {
        const fetchStatus = async () => {
            const response = await SessionService.getCurrentUser().catch(e => console.log(e));
            setUser(response.data);
            setLoading(false);
        }
        fetchStatus();
    }, []);
    
    const showData = () => {
        
        return(
            <div className="card">
                <div className="card-body">
                    <h5 className="header-title">Información de Usuario</h5>
                    <h3 className="mt-2">{user.name}</h3>
                    <small className="text-muted mb-4">Creado el {moment(user.createdAt).format('DD-MM-YYYY')}</small>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group row mt-4">
                                <label className="col-sm-2 col-form-label">E-mail</label>
                                <div className="col-sm-10">
                                    <input type="text" readonly className="form-control-plaintext" value={user.email} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Acceso POS</label>
                                <div className="col-sm-10">
                                    <input type="text" readonly className="form-control-plaintext" value={user.hasPos === 1 ? ('Si') : ('No')} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Hacer Retiros</label>
                                <div className="col-sm-10">
                                    <input type="text" readonly className="form-control-plaintext" value={user.hasPosWithdrawal === 1 ? ('Si') : ('No')} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Acceso Admin</label>
                                <div className="col-sm-10">
                                    <input type="text" readonly className="form-control-plaintext" value={user.hasAdmin === 1 ? ('Si') : ('No')} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Último Acceso</label>
                                <div className="col-sm-10">
                                    <input type="text" readonly className="form-control-plaintext" value={moment(user.lastLogin).format('DD-MM-YYYY HH:mm')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <React.Fragment>
            {loading ? <SpinnerLoader /> : showData()}
        </React.Fragment>
    );
}

export default Profile;