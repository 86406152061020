import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaBarcode } from 'react-icons/fa';

import { PosContext } from '../../Contexts/PosContext';
import * as Notify from '../../Utils/Notify';

const BarcodeSearcher = props => {

    const inputRef = useRef(null);
    const { items, saleItems, setSaleItems } = useContext(PosContext);

    const [searching, setSearching] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        focusInputBar();
    }, [props]);

    const focusInputBar = () => {
        inputRef.current.focus();
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
    }

    const handleKeyUp = async (event) => {
        const { value } = event.target;
        if (value.length > 0) {
            if (event.keyCode === 13) { 
                await searchProduct(value);                    
            }
        }
    }

    const searchProduct = async (value) => {
        setSearching(true);
        const findedItem = items.filter(item => item.barcode === value);
        if (findedItem.length > 0) {
            const { id, barcode, name, canSold, canOrdered, sellingRate, taxPercent, unitShortName, isExempt, active } = findedItem[0];
            if (canSold === 1 && active === 1) {
                const item = { id, barcode, name, sellingRate, canSold, canOrdered, quantity: 1, taxPercent, unitShortName, isExempt,  subtotal: sellingRate };
                if (saleItems.some(e => e.id === item.id)) {
                    let findItem = saleItems.find(t => t.id === item.id);
                    findItem.quantity += 1;
                    findItem.subtotal = findItem.sellingRate * findItem.quantity;
                    setSaleItems(saleItems.map(a => a.id === item.id ? { ...a, findItem } : a));
                } else {
                    setSaleItems([ ...saleItems, item ]);
                }
            }
        } else {
            Notify.send(`El código ${value} no existe registrado como producto`, 'warning');
        }

        setSearchValue('');
        setSearching(false);
        focusInputBar();
    }

    return(
        <div className="row">
            <div className="col-md-12">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><FaBarcode /></span>
                    </div>
                    <input 
                        ref={inputRef} 
                        type="text" 
                        name="item_code" 
                        className="form-control" 
                        onKeyUp={handleKeyUp} 
                        onChange={handleInputChange} 
                        value={searchValue} 
                        disabled={searching} 
                        placeholder="Código Producto" 
                    />
                </div>
            </div>                            
        </div>
    );
}

export default BarcodeSearcher;