import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import SpinnerLoader from '../Layout/SpinnerLoader';
import moment from 'moment';
import CurrencyFormat from '../../Utils/CurrencyFormat';

import SalesServices from './SalesServices';

const SaleModal = (props) => {

    const [loading, setLoading] = useState(true);
    const [document, setDocument] = useState(null);

    useEffect(() => {        
        if (props.saleId !== 0) {
            const fetchSale = async () => {
                const currSale = await SalesServices.get(props.saleId).catch(e => console.log(e));
                setDocument(currSale.data);
            }
            fetchSale();
            setLoading(false);
        }
    }, [props.saleId])
    
    return (
        <Modal show={props.show} size="lg" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FiSearch /> Ver Documento
                </Modal.Title>
                <button onClick={props.onHide} type="button" className="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '300px'}}>
                {loading && document !== null ? 
                (<SpinnerLoader />)
                :
                (
                    <>
                    <table className="table table-bordered table-sm mb-3">
                        <tbody>
                            <tr>
                                <td>Tipo Documento</td>
                                <td>{document?.documentName}</td>
                            </tr>
                            <tr>
                                <td>Folio</td>
                                <td>{document?.sale.documentNumber}</td>
                            </tr>
                            <tr>
                                <td>Fecha Emisión</td>
                                <td>{moment(document?.sale.emissionDate).format('DD/MM/YYYY HH:mm')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th className="text-right">Precio</th>
                                <th className="text-right">Cantidad</th>
                                <th className="text-right">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                        {document?.detail.map((det, i) => {
                            return (
                            <tr key={i}>
                                <td>{det.itemName}</td>
                                <td className="text-right">{CurrencyFormat(det.grossAmount)}</td>
                                <td className="text-right">{det.quantity}</td>
                                <td className="text-right">{CurrencyFormat(det.grossAmount * det.quantity)}</td>
                            </tr>);
                        })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3}>Total</td>
                                <td className="text-right">{CurrencyFormat(document?.sale.totalAmount)}</td>
                            </tr>
                        </tfoot>
                    </table>
                    </>
                )}
                               
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary">Imprimir</button>
                <button type="button" onClick={() => { setDocument(null); props.onHide() }} className="btn btn-secondary">Cerrar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default SaleModal;